enum MediaServerUrlEnum{
    Hosting = "https://pibeanmedia.pibean.com/",
    Local = "http://localhost:4200/"
}

enum WebAPIConnectionUrlEnum{
    Hosting = "https://pibeanapi.pibean.com/api/",
    Local = "https://localhost:7051/api/"
}

enum DomainServerEnum{
    Hosting = "https://nepisir.com/",
    Local = "http://localhost:4200/"
}

export const MediaServerUrl = {
    url: MediaServerUrlEnum.Hosting
}

export const WebAPIConnectionUrl = {
    url: WebAPIConnectionUrlEnum.Hosting
}

export const DomainServer = {
    domain: DomainServerEnum.Hosting
}
